:root {
  --primary: #F6FAF5;
  --primaryFont: black;
  --secondary: #C4DEBC;
  --secondaryFont: black;
  --tertiary: #779063;
  --tertiaryFont: #F6FAF5;
  --inputBox: white;
}

body {
  background-image: url("./images/background.jpg");
  margin: 0rem;
  font-family: sans-serif;
  font-size: 1.3rem;
}

input {
  padding: 0.4rem;
  border: none;
  border-bottom: 1px solid var(--tertiary);
  border-radius: 2px;
}