.weekSelector {
    background-color: var(--tertiary);
    color: var(--tertiaryFont);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 0.3em;
    width: 100%;
}

.arrow {
    padding: 1em;
    width: 1.2em;
    height: 1.2em;
}

.weekSelectorText {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date {
    font-size: 0.9rem;
}