.container {
    background-color: var(--secondary);
    color: var(--secondaryFont);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    border-radius: 0.3em;
}


.button {
    padding: 0.5rem;
    margin: 1rem 0rem;
    border: none;
    background-color: var(--tertiary);
    color: var(--tertiaryFont);
}