.title {
    color: var(--tertiary);
    margin-bottom: 0.2em;
}

.courseContainer {
    display: flex;
    margin-bottom: 0.5em;
}

.input {
    flex: 1;
}