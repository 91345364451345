.menuCard {
    display: flex;
    width: 100%;
    background-color: var(--secondary);
    color: var(--secondaryFont);
}

.weekday {
    color: var(--tertiaryFont);
    background-color: var(--tertiary);
    padding: 0.3em;
}

.weekday.closed {
    width: 2em;
}

.weekday.open {
    width: 100%;
    transition: width 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.daySummary.closed {
    width: 100%;
    height: 100%;
}

.daySummary.open {
    display: none;
}

.menuCardEdit {
    background-color: var(--secondary);
    color: var(--secondaryFont);
    display: block;
    padding: 0.3rem;
}

.menuCardEdit.closed {
    display: none;
}


.menuCardEdit.open {
    display: block;
    animation: appear 0.2s ease-out;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.button {
    width: 1em;
    height: 1em;
}

.titleButtonContainer {
    display: flex;
    direction: row;
    align-items: center;
}

.addCourseButtonContainer {
    animation: move 0.1s ease-out;
}

@keyframes move {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.addCourseButton {
    background-color: var(--tertiaryFont);
    color: var(--tertiary);
    border: none;
    padding: 0.2em 0.4em;
    margin: 0 0.3em;
}