.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary);
    width: 100%;
    height: 4rem;
}

.elementContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left {
    min-width: 2rem;
}

.center {
    font-size: 2rem;
}

.right {
    min-width: 2rem;
}